import React from 'react';
import { Footer } from '../components/footer';
import { Header } from '../components/header';
import { SEO } from '../components/seo';

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <Header />
    <main>
      <p>?</p>
    </main>
    <Footer />
  </>
);

export default NotFoundPage;
